import React from 'react'
import BackgroundImage from 'gatsby-background-image'

import LightButton from '../buttons/light_button'
import { Link } from 'gatsby'

const PageHeader = ({
  background,
  title,
  subtitle,
  buttonTitle,
  buttonLink,
  thisPage,
  className,
  children,
  previousPage,
  previousPageLink,
  padding
}) => {
  return (
    <BackgroundImage
      fluid={background}
      className={`page-header ${className}`}
      style={{ paddingTop: `${padding}`, paddingBottom: `${padding}` }}
    >
      <div className={`overlay-page-header ${className}`}></div>
      <div className="page-header-inner wrap">
        <div className="col-md-7 mx-auto">
          <div className="page-title">
            <h2>{title}</h2>
          </div>
          <div className="page-subtitle">
            <p>{subtitle}</p>
          </div>
          {buttonTitle
            ? (
            <div>
              <div style={{ height: 30 }}></div>
              <LightButton to={buttonLink} title={buttonTitle} />
            </div>
              )
            : null}

          <div style={{ height: 30 }}></div>
          <div className="breadcrumb-explorelogy">
            <div className="breadcrumb-inner">
              <span>
                <Link to="/">Explorelogy</Link>
              </span>

              <span className="link-item">
                {previousPageLink
                  ? (
                  <Link to={previousPageLink}>{previousPage}</Link>
                    )
                  : null}
              </span>

              <span>{thisPage}</span>
            </div>
          </div>
          <div style={{ height: 30 }}></div>
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PageHeader

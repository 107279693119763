import React from "react"
import Layout from "../../components/layout"
import PageHeader from "../../components/header/page-header"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../components/seo"
import SpyMenu from "../../components/menus/spyMenu"
import BackgroundImage from "gatsby-background-image"

import { Section2IconList } from "../../components/Section2IconList"
import PrimaryButton from "../../components/buttons/primary_button"
import MainTitle from "../../components/titles/mainTitle"

import Image from "gatsby-image"
import WhyChooseUsSection from "../../components/whyChooseUsSection"
import ServiceIconBoxes from "../../components/ServiceIconBoxes"
import CustomersSections from "../../components/customersSection"
import SubServiceIconBox from "../../components/SubServiceIconBox"
import Testimonial from "../../components/Testimonial"
import ClientCarousel from "../../components/ClientCarousel"

const ManagedCloudService = () => {
  const data = useStaticQuery(graphql`
    query ManagedCloudService {
      ...IndustryWeServeFragment
      bgImage: file(
        relativePath: { eq: "services/managed-cloud-services.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipad: file(relativePath: { eq: "home/ipad.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamImage: file(
        relativePath: {
          eq: "services/business-concept-development-teamImage.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial1: file(relativePath: { eq: "testomonial/2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial2: file(relativePath: { eq: "testomonial/1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonials: allTestimonialsJson {
        nodes {
          description
          className
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          role
        }
      }
    }
  `)

  const testimonials = data.testimonials.nodes

  const it_support_routes = [
    {
      name: "Benefits",
      route: "benefits",
      offset: -10,
    },
    {
      name: "Solutions",
      route: "solutions",
      offset: 50,
    },
    {
      name: "Case Studies",
      route: "case-studies",
      offset: -55,
    },
    {
      name: "Customers",
      route: "customers",
      offset: -60,
    },
    {
      name: "Why Choose Us",
      route: "why-choose-us",
      offset: -50,
    },
    {
      name: "Other Services",
      route: "other-services",
      offset: -10,
    },
  ]

  const teamImage = data.teamImage.childImageSharp.fluid
  // const backgroundImage = data.teamImage.childImageSharp.fluid
  const ipadImage = data.ipad.childImageSharp.fluid

  const testomonials = [
    {
      name: "Eduplus - Mr.Sujith Liyanage -(Director/Teacher) -  Viduna Institute",
      image: data.testomonial1.childImageSharp.fluid,
      description:
        "Eduplus” is the great product which I found to manage my institute without any hassle from any involved parties such as students, parents & institute staff. The Team Explorelogy worked with us in a very passionate and energetic way with a positive approach that always increased my satisfaction level on their product.",
      className: "testomonial-box custom-border",
    },
    {
      name: "M.K. Daya Priyantha (Director/CEO) - Invos Global (PVT) LTD ",
      image: data.testomonial2.childImageSharp.fluid,
      description:
        "They have the power of Conceptualization of our business idea and the concept from the Digital perspective which helps for the betterment of the company, In simple words they think bigger than we do as business owners. One of my best decisions which I have taken and never regret is selecting “Explorelogy” as an IT partner.",
      className: "testomonial-box-2",
    },
  ]

  const pageTitle = "Managed Cloud Service"
  const pageDescription =
    "Efficient and affordable access to cloud computing and storages"
  return (
    <Layout>
      <SEO title={pageTitle} description={pageDescription} />
      <PageHeader
        className="padding-180"
        title={pageTitle}
        subtitle={pageDescription}
        background={data.bgImage.childImageSharp.fluid}
        thisPage={pageTitle}
        previousPage="IT Services"
        previousPageLink="/services/"
      />
      <SpyMenu routes={it_support_routes} />
      <div id="benefits" className="container-fluid pt-3">
        <div className="col-md-6 mx-auto">
          <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Move with hazel free environment
          </h2>
          {/* <div style={{ height: 20 }}></div> */}
          <div>
            <p style={{ textAlign: "center" }}>
              We provide and manage multiple cloud infrastructure services that
              allow you to function without the need for internal infrastructure
              or the need to host your own applications. We enable you to place
              in-house applications and software on the cloud, as per your
              company requirement.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="container">
          <div className="row">
            {benefits_data.map((item, index) => {
              return (
                <BenefitBox
                  key={index}
                  className={item.className}
                  title={item.title}
                  description={item.description}
                />
              )
            })}
          </div>
        </div>
        <div style={{ height: 200 }}></div>
      </div>
      <div className="container-fluid bg-white">
        <div className="row ">
          <div className="col-8 col-md-4 mx-auto">
            <div
              style={{ marginTop: -120 }}
              className=" text-center position-relative"
            >
              <div>
                <Image className="img-fluid" fluid={ipadImage}></Image>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mx-auto pt-5">
          <h2
            className="what-service"
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            What is Managed cloud Services
          </h2>

          <div>
            <p style={{ textAlign: "center" }}>
              You can come up with a solution where we can support partial or
              complete control of your cloud platforms, including migrations and
              maintenance. We can do optimization as well for run your business
              smoothly and very efficiently.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="col-md-10 mt-0 mb-0 mb-0 mx-auto">
          <div className="row">
            <div className="col-md-7">
              <BackgroundImage
                Tag="div"
                fluid={teamImage}
                className="section-2-team"
              ></BackgroundImage>
            </div>
            <div className="col-md-5">
              <div className="container-box light-blue">
                <h2
                  className="secret-behind"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "100",
                    marginTop: 20,
                  }}
                >
                  Secret Behind Us - Our force
                </h2>
                <div style={{ height: 10 }}></div>
                <ul className="section-2-iconlist">
                  <Section2IconList>
                    Highest rate of retantion 100% for all staff
                  </Section2IconList>
                  <Section2IconList>
                    Our team passionate to welcome challenges
                  </Section2IconList>
                  <Section2IconList>
                    Long term relationships lead to strong business strategies.
                  </Section2IconList>
                  <Section2IconList>
                    Our clients are totally secured by our expert teams.
                  </Section2IconList>
                </ul>
                <div style={{ height: 20 }}></div>
                <PrimaryButton
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "90%",
                  }}
                  title="Find Out More"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="solutions" className="container-fluid bg-white py-5">
        <MainTitle
          title="COMPREHENSIVE IT SERVICES INCLUDE"
          heading="Solutions"
        />
        <div className="container pb-5 pt-5">
          <div className="row">
            {solution_box_data.map((item, index) => {
              return (
                <SubServiceIconBox
                  title={item.title}
                  description={item.description}
                  key={index}
                  icon={item.icon}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div id="case-studies" className="container"></div>
      <CustomersSections>
        {/* <Testimonial testomonial={testimonials} /> */}
        <div className="container">
          <ClientCarousel clientImages={data.clientImages} />
        </div>
      </CustomersSections>
      <WhyChooseUsSection />
      <div id="other-services" className="container-fluid">
        <div className="row mx-0">
          <div className="col-11 col-md-10 mx-auto">
            <div className="column-inner">
              <div style={{ width: "100%" }} className="separator">
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
                <p
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 0,
                    fontSize: "1rem",
                    fontWeight: "100",
                    textAlign: "center",
                  }}
                >
                  Diversified solutions to suit your requirements
                </p>
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
              </div>
              <div style={{ height: 40 }}></div>
              {/* service icon boxes */}
              <ServiceIconBoxes />
              {/* service icon boxes */}
              <div style={{ height: 20 }}></div>
              <div className="text-column pb-5">
                <p style={{ textAlign: "center" }}>
                  IT services built specifically for your business. &nbsp;
                  <a className="link" href="/#">
                    Find your solution
                  </a>
                </p>
              </div>
              {/* <div style={{ height: 230 }}></div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const solution_box_data = [
  {
    title: "Cloud Migration Services",
    description:
      "We manage all aspects of migrating your systems, data, applications and mission-critical elements to the cloud. This includes technical, service and commercial management to ensure a smooth and effortless transfer. ",
    icon: "/images/services/icons/cloud-outline.svg",
  },
  {
    title: "Cloud Infrastructure and Implementation Services",
    description:
      "We build tailored cloud infrastructure that aligns with your organizational needs and business objectives. ",
    icon: "/images/services/icons/cloud-circle-outline.svg",
  },
  {
    title: "Next Generation Enterprise Cloud Security Services",
    description:
      "We assess potential security threats and risks, help design secure cloud based applications, and provide data protection solutions. ",
    icon: "/images/services/icons/analytics-outline.svg",
  },
  {
    title: "Cloud Deployment & Integration",
    description:
      "We help you to deploy your application and software to the cloud and enable real time exchange of data and processing by connecting all Apps and systems in a way that cloud services can be accessed by multiple devices over a single network. ",
    icon: "/images/services/icons/git-branch-outline.svg",
  },
  {
    title: "Support & Managed Services",
    description:
      "We provide both preventive and proactive IT management services that allow real time performance monitoring and scalability as well as reactive IT service support which is a one time fix for failures and malfunctions.",
    icon: "/images/services/icons/build-outline.svg",
  },
  {
    title: "Container Base Services",
    description:
      "We help your software to run without error even when shifted from one computing environment to another by keeping your applications within one package during transition. ",
    icon: "/images/services/icons/cloud-done-outline.svg",
  },
  {
    title: "Cloud Orchestration",
    description:
      "We use programming to connect automated tasks into a cohesive workflow making your systems and applications all the more efficient.",
    icon: "/images/services/icons/color-fill-outline.svg",
  },
]

const BenefitBox = ({ className, title, description }) => {
  return (
    <div className="col-md-3">
      <div className={`benefit-box ${className}`}>
        <h4 style={{ marginTop: 0, marginBottom: 15, color: "#fff" }}>
          <span>{title}</span>
        </h4>
        <p style={{ color: "#fff" }}>{description}</p>
      </div>
    </div>
  )
}

const benefits_data = [
  {
    title: "Unique selling point",
    description:
      "The cloud services we provide are tailored and priced to suit your budget and business requirement. If you wish to expand your cloud storage or enhance your applications we are flexible to your needs. ",
    className: "lightblue",
  },
  {
    title: "Cost control",
    description:
      "Partnering with Explorelogy means you no longer have to pay for your own infrastructure and software licenses.",
    className: "blue",
  },
  {
    title: "Business benefit",
    description:
      "Without the additional investment cost, you are able enhance applications, expand on software and scale your business according to your existing needs.",
    className: "lightorange",
  },
  {
    title: "Suited for you",
    description:
      "If you aren’t ready to invest in your own infrastructure, software, or staff required to man and manage those services, Explorelogy can provide you with just the right solution.       ",
    className: "darkblue",
  },
]

export default ManagedCloudService

import React, { Component } from 'react'

import $ from 'jquery'

import { useLocation } from '@reach/router'

// import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

class SpyMenu extends Component {
  componentDidMount () {
    // scroll company-menu
    const navMenu = $('.nav-menu-wrap')
    const header = $('.page-header')
    const headerHeight = header.height() + 160
    const spyMenu = $('#menu-company')

    let lastId
    const menuItems = spyMenu.find('a')

    const scrollItems = menuItems.map(function () {
      const item = $($(this).attr('href'))
      if (item.length) {
        return item
      }
      return null
    })

    menuItems.click(function (e) {
      const href = $(this).attr('href')
      const navItemOffset = $(this).attr('offset')
      const offsetTop =
        href === '#'
          ? headerHeight
          : $(href).offset().top - Math.abs(navItemOffset) //! set offset
      $('html').stop().animate(
        {
          scrollTop: offsetTop
        },
        {
          duration: 500
        }
      )
      e.preventDefault()
    })

    $(window).scroll(function () {
      const fromTop = $(this).scrollTop() + 100
      // Get id of current scroll item
      let cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop) {
          return this
        }
        return 0
      })

      cur = cur[cur.length - 1]
      const id = cur && cur.length ? cur[0].id : ''

      if (lastId !== id) {
        lastId = id
        // Set/remove active class
        menuItems
          .parent()
          .removeClass('active')
          .end()
          .filter("[href='#" + id + "']")
          .parent()
          .addClass('active')
      }

      if ($(this).scrollTop() > headerHeight) {
        navMenu.addClass('active')
      } else {
        navMenu.removeClass('active')
      }
    })
  }

  render () {
    const { routes } = this.props
    return (
      <div style={{ height: 62 }} className="d-none d-md-block">
        <div className="nav-menu-wrap">
          <div className="container">
            <div className="col-sm-12">
              <div className="nav-menu-wrap">
                <div id="nav-menu-company" className="nav-menu-company widget">
                  <h3 className="spyMenu-title">{this.props.menuTitle}</h3>
                  <div className="nav-menu-company-container">
                    <ul id="menu-company" className="menu spy-menu">
                      {routes &&
                        routes.map((route, index) => (
                          <NavMenuItem
                            offset={route.offset}
                            key={index}
                            to={route.route}
                          >
                            {route.name}
                          </NavMenuItem>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const NavMenuItem = ({ to, children, offset }) => {
  // /company/vision-mission
  const pathname = useLocation().pathname
  const isActive = pathname === to
  // console.log({ pathname, to, isActive })
  const className = isActive ? 'active' : ''
  return (
    <li className={`menu-company-item  ${className}`}>
      <a
        href={`#${to}`}
        className="menu-scroll-link"
        style={{ cursor: 'pointer' }}
        offset={offset}
      >
        {children}
      </a>
    </li>
  )
}

export default SpyMenu

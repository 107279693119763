import React from 'react'

const SubServiceIconBox = ({ title, description, icon }) => {
  return (
    <div className="col-md-6 pb-5 px-5">
      <div className="service-icon-box-2">
        <div className="icon">
          <img
            className="service-icon"
            src={icon}
            alt="explorelogy service icon"
          />
        </div>
        <div className="content">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default SubServiceIconBox

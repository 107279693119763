import React from "react"

const whyChooseUsData = [
  {
    title: "Customer first",
    image: "/images/why-choose-icons/people-outline.svg",
    description:
      "Explorelogy puts our customers first. We prioritise your needs and work within your timelines to get the job done the way you want. ",
  },
  {
    title: "Industry expertise ",
    image: "/images/why-choose-icons/ribbon-outline.svg",
    description:
      "Our team consists of industry experts and professionals with experience in providing IT solutions to a range of businesses working in diverse industries. ",
  },
  {
    title: "Easy experience",
    image: "/images/why-choose-icons/library-outline.svg",
    description:
      "We understand that getting into technicalities can be exhausting. We are here to simplify the tech-stuff and provide you with an easy experience. ",
  },
  {
    title: "Diverse possibilities",
    image: "/images/why-choose-icons/infinite-outline.svg",
    description:
      "We are experienced in a spectrum of technologies, software and applications and are experts at providing diverse solutions that are innovative and efficient.",
  },
  {
    title: "Customised solutions",
    image: "/images/why-choose-icons/options-outline.svg",
    description:
      "Every IT solution we provide will cater to your business requirements and engage in approaches that best remedy the challenges you face.",
  },
  {
    title: "A long run partner",
    image: "/images/why-choose-icons/pulse-outline.svg",
    description:
      "We don’t stop at interim results. We enable long term solutions and can provide you with continued assistance till your company is ready to take over.",
  },
]

const WhyChooseUsSection = () => {
  return (
    <div className="container-fluid why-choose-us" id="why-choose-us">
      <div className="col-md-5 col-lg-7 mx-auto">
        <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontSize: 10,
            fontWeight: 100,
            letterSpacing: 4,
          }}
        >
          WHY CHOOSE US
        </h6>
        <div style={{ height: 6 }}></div>
        <h2
          className="m-0"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "normal",
            fontWeight: "100",
          }}
        >
          6 reasons Explorelogy is the solution your company needs
        </h2>

        <div style={{ height: 10 }}></div>

        <div className="title-hr"></div>
      </div>
      <div style={{ height: 60 }}></div>
      <div className="container">
        <div className="row">
          {whyChooseUsData.map((item, index) => (
            <WhyChooseIconBox
              key={index}
              title={item.title}
              image={item.image}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const WhyChooseIconBox = ({ title, image, description }) => {
  return (
    <div className="col-md-4 px-3 pb-5">
      <div className="icon-box">
        <div className="icon">
          <img src={image} alt="icon-why-choose-us" />
        </div>
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUsSection

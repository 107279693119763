import React from 'react'

const CustomersSections = ({ children }) => {
  return (
    <div>
      <div
        style={{ backgroundColor: '#322943' }}
        id="customers"
        className="container-fluid "
      >
        <section className="customers-section m-0">
          <div className="col-md-6 mx-auto">
            <div>
              <h6
                style={{
                  fontSize: 10,
                  textAlign: 'center',
                  margin: 0,
                  textTransform: 'uppercase',
                  letterSpacing: 4
                }}
              >
                INDUSTRIES WE SERVE
              </h6>
            </div>
            <div style={{ height: 6 }}></div>
            <h2 style={{ color: '#ffffff', textAlign: 'center', margin: 0 }}>
              WE WORK TOGETHER WITH OUR BUSINESS PARTNERS
            </h2>
            <div style={{ height: 20 }}></div>
            <p style={{ textAlign: 'center' }}>
              Our standards and expertise knowledge allows you to uplift your
              business from existing level to brand new level and streamline
              your business operation which increases your productivity. No
              matter from which industry you are, we will cover your
              requirements with our solutions without any doubt.
            </p>
          </div>
        </section>
        {children}
        <div style={{ height: 80 }}></div>
      </div>
    </div>
  )
}

export default CustomersSections

import React from "react"
import OwlCarousel from "react-owl-carousel2"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const ClientCarousel = ({ clientImages }) => {
  return (
    <OwlCarousel
      options={{
        items: 5,
        loop: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 2000,
        responsive: {
          0: {
            items: 2,
            nav: false,
            rewind: true,
            autoplay: true,
          },
          766: {
            items: 5,
            nav: false,
            rewind: true,
            autoplay: true,
          },
          1200: {
            items: 5,
            nav: false,
            rewind: true,
            autoplay: true,
          },
        },
      }}
    >
      {clientImages.nodes.map((e, index) => {
        const image = getImage(e)
        return (
          <div key={index} className="client-logo">
            <GatsbyImage image={image} alt="" />
          </div>
        )
      })}
    </OwlCarousel>
  )
}

export const query = graphql`
  fragment IndustryWeServeFragment on Query {
    clientImages: allFile(filter: { relativeDirectory: { eq: "clients" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
`

export default ClientCarousel

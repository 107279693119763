import React from 'react'

const MainTitle = ({ title, heading }) => {
  return (
    <div className="col-md-6 mx-auto pt-5 pb-3">
      <h6
        className="m-0"
        style={{
          textAlign: 'center',
          fontSize: 10,
          fontWeight: 100,
          letterSpacing: 4,
          textTransform: 'uppercase'
        }}
      >
        {heading}
      </h6>
      <div style={{ height: 6 }}></div>
      <h2
        className="m-0 main-title"
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontSize: 'normal',
          fontWeight: '100'
        }}
      >
        {title}
      </h2>
      <div style={{ height: 20 }}></div>
      <div className="title-hr"></div>
    </div>
  )
}

export default MainTitle
